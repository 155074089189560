<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      fullscreen
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? `${$t('Update Sale')} ${formData.invoice_no}` : $t('Add New Sale') }}
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                  sm="auto"
                  md="auto"
                >
                  <app-branch-select
                    ref="appItemSearch"
                    v-model="formData.branch_id"
                    :disabled="editMode"
                    @branch-selected="branchSelected($event)"
                  />
                </v-col>

                <v-col
                  class="px-1"
                  sm="3"
                  md="3"
                  lg="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Customer')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.customer_id"
                      :label="`* ${$t('Customer')}`"
                      :items="customersList"
                      :item-text="item => `${item.name} | ${item.phone1}`"
                      :item-value="item => item.id"
                      outlined
                      dense
                      :disabled="editMode"
                      :color="$_input_color()"
                      :error-messages="errors"
                      @input="customerSelected(formData.customer_id)"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-2"
                  sm="3"
                  md="auto"
                  lg="auto"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('By Purchase Price')"
                  >
                    <v-checkbox
                      v-model="formData.is_by_purchase_price"
                      :label="$t('By Purchase Price')"
                      :error-messages="errors"
                      dense
                      :ripple="false"
                    />
                  </validation-provider>
                </v-col>

                <v-col class="px-1 mt-1 text-subtitle-1 font-weight-bold mb-4">
                  <v-row justify="space-around">
                    <v-col
                      cols="auto"
                      class="warning--text"
                    >
                      {{ $t('Customer Balance') }}
                      {{ $_format_number(formData.customer_balance) }}
                    </v-col>

                    <v-col
                      cols="auto"
                      class="warning--text"
                    >
                      {{ $t('Net Price') }}
                      {{ $_format_number(formData.net_price) }}
                    </v-col>

                    <v-col
                      cols="auto"
                      class="warning--text"
                    >
                      {{ $t('Monthly Receipt Amount') }}
                      {{ $_format_number(formData.monthly_receipt_amount) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="2"
                  lg="2"
                  xl="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Inventory')"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="formData.inventory_id"
                      :label="`* ${$t('Inventory')}`"
                      :items="inventoriesList"
                      :item-text="item => item.name"
                      :item-value="item => item.id"
                      outlined
                      dense
                      :disabled="editMode"
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1 me-2 d-flex justify-center"
                  cols="12"
                  sm="3"
                  md="auto"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Cash or Debt')"
                    rules="required"
                  >
                    <v-radio-group
                      v-model="formData.is_cash"
                      row
                      class="mt-1"
                      :readonly="editMode"
                      :error-messages="errors"
                    >
                      <v-radio
                        :label="$t('Cash')"
                        :value="true"
                        color="primary"
                        :ripple="false"
                      />
                      <v-radio
                        :label="$t('Debt')"
                        :value="false"
                        color="warning"
                        :ripple="false"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm
                  md
                  lg="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('No. of months')"
                  >
                    <v-select
                      v-model="formData.no_of_months"
                      :label="$t('No. of months')"
                      :items="monthPlanList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="2"
                  lg="1"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Pre Paid')"
                  >
                    <v-currency-field
                      v-model="formData.pre_paid"
                      :label="$t('Pre Paid')"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Excluded Month')"
                  >
                    <v-select
                      v-model="formData.excluded_month"
                      :label="$t('Excluded Month')"
                      :items="monthsList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="4"
                  md="3"
                  lg
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Month Frequency')"
                    :rules="formData.no_of_months ? 'required' : ''"
                  >
                    <v-select
                      v-model="formData.month_frequency"
                      :label="$t('Month Frequency')"
                      :items="monthsList"
                      outlined
                      dense
                      clearable
                      :color="$_input_color()"
                      :menu-props="{'offset-y': true}"
                      :error-messages="errors"
                      @input="monthFrequencySelected(formData.month_frequency)"
                    >
                      <template #item="{item}">
                        {{ item.text }} {{ $t('month a time') }}
                      </template>
                      <template #selection="{item}">
                        {{ item.text }} {{ $t('month a time') }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="4"
                  lg
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Next Receipt Date')"
                    :rules="formData.no_of_months ? 'required' : ''"
                  >
                    <app-date-picker
                      v-model="formData.next_receipt_date"
                      :label="$t('Next Receipt Date')"
                      no-title
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="12"
                  md="4"
                  lg
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Note')"
                  >
                    <v-text-field
                      v-model="formData.note"
                      :label="$t('Note')"
                      outlined
                      dense
                      :color="$_input_color()"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="justify-space-between"
              >
                <v-col
                  class="px-1"
                  cols="12"
                  sm="5"
                  md="4"
                  lg="4"
                >
                  <app-item-search
                    exist
                    endpoint="stock/search/"
                    :branch-id="formData.branch_id"
                    :inventory-id="formData.inventory_id"
                    @selected="handleItemSelect($event)"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="mt-6"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>{{ $t('Code') }}</th>
                          <th>{{ $t('Name') }}</th>
                          <th
                            style="width: 200px"
                            class="text-center"
                          >
                            {{ $t('Remain Quantity') }}
                          </th>
                          <th
                            style="width: 150px"
                            class="text-center"
                          >
                            {{ $t('Packet') }}
                          </th>
                          <th
                            style="width: 150px"
                            class="text-center"
                          >
                            {{ $t('Quantity') }}
                          </th>
                          <th style="width: 200px">
                            {{ $t('Sale Price') }}
                          </th>
                          <th
                            style="width: 200px"
                            class="text-center"
                          >
                            {{ $t('Total Price') }}
                          </th>
                          <th
                            style="width: 80px"
                            class="text-center"
                          >
                            {{ $t('Actions') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(detail, index) in formData.details"
                          :key="index"
                        >
                          <td>{{ detail.item_code }}</td>
                          <td>{{ detail.item_name }}</td>
                          <td class="text-center">
                            {{ $_format_number(detail.stock_quantity) }}
                          </td>
                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Packet') + (index + 1)"
                            >
                              <v-currency-field
                                v-model="detail.packet"
                                :label="`* ${$t('Packet')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                                @input="calcQuantity(detail)"
                              />
                            </validation-provider>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Quantity') + (index + 1)"
                              :rules="`required|min_value:0.1|max_value:${detail.stock_quantity}`"
                            >
                              <v-currency-field
                                v-model="detail.quantity"
                                :label="`* ${$t('Quantity')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td>
                            <validation-provider
                              v-slot="{ errors }"
                              :name="$t('Sale Price') + (index + 1)"
                              rules="required|min_value:0.1"
                            >
                              <v-currency-field
                                v-model="detail.sale_price"
                                :label="`* ${$t('Sale Price')}`"
                                outlined
                                dense
                                single-line
                                hide-details="auto"
                                :class="$_small_input()"
                                :color="$_input_color()"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </td>

                          <td class="text-center">
                            {{ $_format_number(detail.quantity * detail.sale_price) }}
                          </td>

                          <td class="text-center">
                            <v-btn
                              v-if="!detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="removeDetail(index)"
                            >
                              <v-icon class="mdil-rotate-45">
                                mdil-plus
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-if="detail.id"
                              color="error"
                              icon
                              small
                              class="rounded"
                              depressed
                              outlined
                              @click="deleteDetail(detail.id, index)"
                            >
                              <v-icon small>
                                mdil-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <app-submit-button :edit-mode="editMode" />
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  data() {
    return {
      inventoriesList: [],
      customersList: [],
    }
  },

  computed: {
    ...mapGetters([
      'monthsList',
      'monthPlanList',
    ]),
  },

  watch: {
    addEditDialog: {
      immediate: false,
      handler(val) {
        if (val && this.editMode) {
          this.customerSelected(this.formData.customer_id)
        }
      }
    },

    formData: {
      immediate: false,
      deep: true,
      handler() {
        this.calculate()
      }
    }
  },

  methods: {
    calcQuantity(detail) {
      if (!detail.per_packet) return

      detail.quantity = detail.packet * detail.per_packet
    },

    monthFrequencySelected(value) {
      if (!value) {
        this.formData.next_receipt_date = null
      } else {
        this.formData.next_receipt_date = Vue.moment(new Date()).add(Number(value), 'M').format('YYYY-MM-DD')
      }
    },

    customerSelected(id) {
      axios.get(`customer/${id}/get-balance/`).then(res => {
        Vue.set(this.formData, 'customer_balance', res.data.balance)
      })
    },

    branchSelected(data) {
      this.inventoriesList = data.inventories
      this.formData.inventory_id = data.inventories[0].id
      this.customersList = data.customers
    },

    handleItemSelect(item) {
      const itemExist = this.formData.details.find(detail => detail.stock_id == item.stock_id)

      if (itemExist) {
        return this.$_notify('This item already is in the list.', 'warning', 4000)
      }

      this.formData.details.push({
        item_code: item.item_code,
        item_name: item.item_name,
        item_id: item.item_id,
        stock_id: item.stock_id,
        packet: null,
        per_packet: item.per_packet,
        quantity: null,
        stock_quantity: item.stock_quantity,
        sale_price: this.formData.is_by_purchase_price ? item.item_purchase_price : item.item_sale_price,
        item_sale_price: item.item_sale_price,
        item_purchase_price: item.item_purchase_price,
      })
    },

    removeDetail(index) {
      this.formData.details.splice(index, 1)
    },

    deleteDetail(id, index) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(`sale-detail/${id}/`).then(() => {
            this.$_notify('Deleted successfully')
            this.formData.details.splice(index, 1)
          }).then(() => {
            // update sale i order to update monthly plan automatically
            axios.put(`sale/${this.formData.id}/`, this.formData).then(() => {
              this.$_notify('Updated successfully')
              this.$emit('update-table-data')
            })
          })
        }
      })
    },

    calculate() {
      let totalPrice = 0
      let totalQuantity = 0

      this.formData.details.forEach(detail => {
        detail.sale_price = this.formData.is_by_purchase_price ? detail.item_purchase_price : detail.sale_price
        totalPrice = totalPrice + (Number(detail.quantity ?? 0) * Number(detail.sale_price ?? 0))
        totalQuantity += Number(detail.quantity ?? 0)
      })

      let discount = this.formData.discount ?? 0
      Vue.set(this.formData, 'total_price', totalPrice)
      Vue.set(this.formData, 'net_price', totalPrice - discount)
      Vue.set(this.formData, 'total_quantity', totalQuantity)


      if (this.formData.is_cash) {
        this.formData.pre_paid = 0
        this.formData.no_of_months = null
        this.formData.excluded_month = null
        this.formData.month_frequency = null
        Vue.set(this.formData, 'monthly_receipt_amount', null)
      } else {
        if (this.editMode) {
          // get old_balance
          let customerBalance = Math.abs(this.formData.customer_balance ?? 0)
          const oldPrePaid = this.formData.old_pre_paid ?? 0
          const oldNetPrice = this.formData.old_net_price ?? 0
          const diff = oldNetPrice - oldPrePaid
          let oldBalance = customerBalance - diff

          // calculate receipt amount on old_balance
          const prePaid = this.formData.pre_paid ?? 0
          const saleRemain = this.formData.net_price - prePaid
          customerBalance = oldBalance + saleRemain
          let monthlyReceiptAmount = customerBalance / this.formData.no_of_months
          Vue.set(this.formData, 'monthly_receipt_amount', monthlyReceiptAmount)
        } else {
          let customerBalance = Math.abs(this.formData.customer_balance ?? 0)
          const prePaid = this.formData.pre_paid ?? 0
          const saleRemain = this.formData.net_price - prePaid
          customerBalance = customerBalance + saleRemain
          let monthlyReceiptAmount = customerBalance / this.formData.no_of_months
          Vue.set(this.formData, 'monthly_receipt_amount', monthlyReceiptAmount)
        }
      }
    },

    closeDialog() {
      this.$refs.theForm.reset()
      this.formData.branch_id = -1
      this.$emit('close')
    },

    submit() {
      this.$_confirm({ type: 'approve' }).then(confirmed => {
        if (confirmed) {
          if (this.editMode) {
            axios.put(`sale/${this.formData.id}/`, this.formData).then(() => {
              this.$_notify('Updated successfully')
              this.closeDialog()
              this.$emit('update-table-data')
            })
          } else {
            axios.post('sale/', this.formData).then(() => {
              this.$_notify('Added successfully')
              this.closeDialog()
              this.$emit('reset-table-data')
            })
          }
        }
      })
    }
  },
}
</script>
<style lang="scss">
</style>